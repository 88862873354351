module.exports = [
   {
      "name": "facebook",
      "title": "Facebook for Business",
      "employer": "Crystal Equation",
      "category": "Web, Marketing & Analytics",
      "type": "Web, Marketing&nbsp;& Analytics",
      "description": "Created and maintained Facebook global multimedia marketing campaigns & UI assets to global Facebook’s active Business users. \n Collaborated with cross functional teams of Marketing Managers, Designers, Marketing Ops, Engineers and i18n to scope campaigns and lead the executions. \n Built UI and Applications using React and Hack Language, integrating it into an inhouse CMS system that’s compliant with Accessibility and i18n standards; allowing new capabilities for other CMS Developers Provided technical knowledge to onboard other CMS/ Web Developers. \n Created technical documentation and specs which communicate to Web Developers and Marketing Managers.",
      "next_project": "charlotterusse.html",
      "client": "Facebook",
      "banner_link": "facebook/facebook",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_class": "",
            "tool_name": "HTML"
         },
         {
            "tool_class": "",
            "tool_name": "JavaScript"
         },
         {
            "tool_class": "",
            "tool_name": "React"
         },
         {
            "tool_class": "",
            "tool_name": "ES6"
         },
         {
            "tool_class": "",
            "tool_name": "Hack Language (PHP)"
         },
         {
            "tool_class": "",
            "tool_name": "CSS"
         },
         {
            "tool_class": "",
            "tool_name": "XML"
         },
         {
            "tool_class": "",
            "tool_name": "SQL"
         }
      ],
      "vimeo_status": "unlinked",
      "vimeo": "",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/facebook/01.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/facebook/02.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/facebook/03.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/facebook/04.jpg"
         }
      ]
   },
   {
      "name": "charlotterusse",
      "title": "Charlotte Russe",
      "employer": null,
      "category": "Web, Marketing & Analytics",
      "type": "Web, Marketing&nbsp;& Analytics",
      "description": "Maintained CharlotteRusse.com daily management including daily content/ promo changes, overall performance. \n Collaborated cross functionally with COO, CTO, Directors, Marketing Managers and Designers to scope strategic planning and marketing campaigns based on analytical data implemented by FE Developer team. \n Led a team of inhouse and overseas FE Developers. Worked with a team of Technical Managers, Project Managers, QA Analysts and Backend Engineers for weekly production push. Contributed for agile cadence in technical process for weekly campaign push and bi-weekly UI/UX improvements. \n Led technical transitions from vendors to in-house team, adopting in-house production push cadence. \n Led a website redesign with jQuery to ES6 conversion and WCAG AA compliance. \n Created and maintained technical documentation and specs for department wide usage. Onboarded technical new hires.",
      "next_project": "sutterfield.html",
      "client": "Charlotte Russe",
      "banner_link": "charlotterusse/charlotterusse",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_class": "",
            "tool_name": "HTML"
         },
         {
            "tool_class": "",
            "tool_name": "CSS"
         },
         {
            "tool_class": "",
            "tool_name": "JavaScript"
         },
         {
            "tool_class": "",
            "tool_name": "ES6"
         },
         {
            "tool_class": "",
            "tool_name": "XML"
         },
         {
            "tool_class": "",
            "tool_name": "Gulp"
         },
         {
            "tool_class": "",
            "tool_name": "Grunt"
         },
         {
            "tool_class": "",
            "tool_name": "Salesforce Commerce Cloud"
         }
      ],
      "vimeo_status": "unlinked",
      "vimeo": "",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/charlotterusse/01.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/charlotterusse/02.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/charlotterusse/03.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/charlotterusse/04.jpg"
         }
      ]
   },
   {
      "name": "sutterfield",
      "title": "Sutterfield Consignment Gallery",
      "employer": null,
      "category": "Web, Marketing & Analytics",
      "type": "Branding, Web & Social, Marketing&nbsp;& Analytics",
      "description": "Previously, Sutterfield had a Wordpress website and inconsistent branding across the board. I was able to straigthen it up and remade the website so it is linked to Flickr instead of updating every single changing inventory. With social media management and analytical strategies I was able to increase traffic significantly.",
      "next_project": "apple.html",
      "client": "Sutterfield",
      "banner_link": "sutterfield/sutterfield",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_class": "",
            "tool_name": "HTML"
         },
         {
            "tool_class": "",
            "tool_name": "CSS"
         },
         {
            "tool_class": "",
            "tool_name": "JavaScript"
         },
         {
            "tool_class": "",
            "tool_name": "React"
         },
         {
            "tool_class": "",
            "tool_name": "ES6"
         },
         {
            "tool_class": "",
            "tool_name": "Node.js"
         },
         {
            "tool_class": "icon-id",
            "tool_name": "inDesign"
         },
         {
            "tool_class": "icon-ai",
            "tool_name": "Illustrator"
         },
         {
            "tool_class": "icon-ps",
            "tool_name": "Photoshop"
         }
      ],
      "vimeo_status": "unlinked",
      "vimeo": "",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/sutterfield/01.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/sutterfield/02.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/sutterfield/03.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/sutterfield/04.jpg"
         }
      ]
   },
   {
      "name": "apple",
      "title": "Apple iPhone 6",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Web Experience",
      "description": "iPhone 6 isn’t simply bigger — it’s better in every way. Larger, yet dramatically thinner. More powerful, but remarkably power efficient. With a smooth metal surface that seamlessly meets the new Retina HD display. It’s one continuous form where hardware and software function in perfect unison, creating a new generation of iPhone that’s better by any measure.",
      "next_project": "mobile-orchestra.html",
      "client": "Apple",
      "banner_link": "apple/apple",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5"
         },
         {
            "tool_name": "CSS3 (Sass)"
         }
      ],
      "vimeo_status": "unlinked",
      "vimeo": "",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/apple/01.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/apple/02.jpg"
         }
      ]
   },
   {
      "name": "levis",
      "title": "Levi's #MAKEOURMARK",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Interactive Experience",
      "description": "Levi’s teamed up with Dave Eggers to create the Station to Station project - a tour of international artists, musicians, writers and thinkers transported by train across America. #MakeOurMark was the accompanying digital campaign that brought the experience to social networks around the world.",
      "next_project": "march-hoops.html",
      "client": "Levi's",
      "banner_link": "akqa/levis",
      "awards": [
         {
            "award_name": "<strong>Featured in:</strong>"
         },
         {
            "award_name": "Cannes Lions 2014 as <br/> Silver Lion of Social Lions."
         },
         {
            "award_name": "Cannes Lions 2014 as <br/> Bronze Lion of Branded Tech Lions."
         },
         {
            "award_name": "Communication Arts."
         },
         {
            "award_name": "Digiday."
         },
         {
            "award_name": "Mediapost."
         },
         {
            "award_name": "PSFK."
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5"
         },
         {
            "tool_name": "CSS3"
         },
         {
            "tool_name": "JavaScript"
         },
         {
            "tool_name": "Middleman"
         }
      ],
      "vimeo_status": "linked",
      "vimeo": "<p>Password: crd@AKQA</p><iframe src=\"//player.vimeo.com/video/106555889?byline=0&amp;portrait=0\" width=\"960\" height=\"540\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>",
      "youtube_status": "linked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/akqa/levis_full.jpg"
         }
      ]
   },
   {
      "name": "audi",
      "title": "Audi A8 Test Drive",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Microsite",
      "description": "Audi Micro-site for users to test drive the Audi's latest 2013 Audi A8 Model.",
      "next_project": "batik-keris.html",
      "client": "Audi",
      "banner_link": "akqa/audi",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5"
         },
         {
            "tool_name": "CSS3"
         },
         {
            "tool_name": "JavaScript"
         },
         {
            "tool_name": "Middleman"
         }
      ],
      "vimeo_status": "unlinked",
      "vimeo": "",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/audi/01.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/audi/02.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/audi/03.jpg"
         }
      ]
   },
   {
      "name": "akqa",
      "title": "Mobile Orchestra",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Interactive Experience",
      "description": "To celebrate the holidays, AKQA teamed up with members of the Pacific Chamber Symphony, and Music Director Laurence Kohl, to create an interactive mobile orchestra that turns phones into a musicians.",
      "next_project": "make-our-mark.html",
      "client": "AKQA",
      "banner_link": "levis/mobileorchestra",
      "awards": [
         {
            "award_name": "<strong>Featured in:</strong>"
         },
         {
            "award_name": "Cannes Lions 2013 as <br/>Silver Lion of Mobile Lions."
         },
         {
            "award_name": "Communication Arts Interactive Annual."
         },
         {
            "award_name": "FWA as Mobile of the Day."
         },
         {
            "award_name": "Google Creative Sandbox."
         },
         {
            "award_name": "PSFK."
         },
         {
            "award_name": "The Egotist."
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5 (Jade)"
         },
         {
            "tool_name": "CSS3 (Stylus)"
         },
         {
            "tool_name": "Node.js"
         }
      ],
      "vimeo_status": "linked",
      "vimeo": "<p>Password: crd@AKQA</p><iframe src=\"//player.vimeo.com/video/96371794?byline=0&amp;portrait=0\" width=\"960\" height=\"540\"  frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>",
      "youtube_status": "linked",
      "youtube": "<iframe width=\"960\" height=\"540\" src=\"//www.youtube.com/embed/wUpAvTYAOjY\" frameborder=\"0\" allowfullscreen></iframe>",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/levis/mobileorchestra_full.jpg"
         }
      ]
   },
   {
      "name": "march_hoops",
      "title": "Bud Light Mad Things Happen March Hoops",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Interactive Experience",
      "description": "To celebrate 2014 NCAA March Madness, Bud Light created experiences and tool swhere everybody can enjoy the \"Madness\" without leaving their desk.",
      "next_project": "bud-light-hotel.html",
      "client": "AB-inbev (Bud Light)",
      "banner_link": "budlight/marchhoops",
      "awards": [
         {
            "award_name": "<strong>Featured in:</strong>"
         },
         {
            "award_name": "Mashable."
         },
         {
            "award_name": "PSFK."
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5"
         },
         {
            "tool_name": "CSS3"
         },
         {
            "tool_name": "JavaScript"
         },
         {
            "tool_name": "Sports Data API"
         },
         {
            "tool_name": "Ruby on Rails"
         }
      ],
      "vimeo_status": "linked",
      "vimeo": "<iframe src=\"//player.vimeo.com/video/96354350?byline=0&amp;portrait=0\" width=\"960\" height=\"540\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> <p>Password: crd@AKQA</p>",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/march_hoops/01.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/march_hoops/02.jpg"
         }
      ]
   },
   {
      "name": "budlight",
      "title": "Bud Light Hotel (Superbowl 2014)",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Web Experience",
      "description": "To celebrate Super Bowl XLVIII, Bud Light created a sweepstakes experience where the winner can take a trip to Bud Light Hotel.",
      "next_project": "jordan-winter.html",
      "client": "Jordan (Nike)",
      "banner_link": "budlight/budlight",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5"
         },
         {
            "tool_name": "CSS3"
         },
         {
            "tool_name": "JavaScript"
         },
         {
            "tool_name": "Facebook API"
         },
         {
            "tool_name": "Ruby on Rails"
         }
      ],
      "vimeo_status": "linked",
      "vimeo": "<iframe src=\"//player.vimeo.com/video/96371790?byline=0&amp;portrait=0\" width=\"960\" height=\"540\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> <p>Password: crd@AKQA</p>",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/budlight/budlight_hotel.jpg"
         }
      ]
   },
   {
      "name": "jordan",
      "title": "Jordan Winter Collection 2013 Defy Winter",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Web Experience",
      "description": "Jordan created a microsite to released their Winter 2013 Collection.",
      "next_project": "salesforce-guide.html",
      "client": "Jordan (Nike)",
      "banner_link": "jordan/jordan",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5"
         },
         {
            "tool_name": "CSS3"
         },
         {
            "tool_name": "JavaScript"
         },
         {
            "tool_name": "Middleman"
         },
         {
            "tool_name": "NodeJS"
         }
      ],
      "vimeo_status": "linked",
      "vimeo": "<iframe src=\"//player.vimeo.com/video/96371791?byline=0&amp;portrait=0\" width=\"960\" height=\"540\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> <p>Password: crd@AKQA</p>",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/jordan/01.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/jordan/02.jpg"
         },
         {
            "media_name": "",
            "media_link": "gallery/jordan/03.jpg"
         }
      ]
   },
   {
      "name": "salesforce",
      "title": "Salesforce Interactive Guide",
      "employer": "AKQA",
      "category": "WEB + INTERACTIVE",
      "type": "Web Experience",
      "description": "Salesforce created an Interactive Guide for their main product to replace their old video non-interactive demo.",
      "next_project": "audi-a8-test-drive.html",
      "client": "Salesforce",
      "banner_link": "salesforce/salesforce",
      "awards": [
         {
            "award_name": ""
         }
      ],
      "tools": [
         {
            "tool_name": "HTML5"
         },
         {
            "tool_name": "CSS3"
         },
         {
            "tool_name": "JavaScript"
         },
         {
            "tool_name": "Middleman"
         }
      ],
      "vimeo_status": "linked",
      "vimeo": "<iframe src=\"//player.vimeo.com/video/96435079?byline=0&amp;portrait=0\" width=\"960\" height=\"540\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> <p>Password: crd@AKQA</p>",
      "youtube_status": "unlinked",
      "youtube": "",
      "media": [
         {
            "media_name": "",
            "media_link": "gallery/salesforce/salesforce_full.jpg"
         }
      ]
   }
];